
import { StaticImage } from "gatsby-plugin-image";
import React from "react"
import Layout from "./components/layout"
import { Link } from "gatsby";

const About = () =>
  <Layout pageTitle="About">
    <div className="content">
      <header>
        <StaticImage
          src="../assets/OrePotraitNature.jpeg"
          alt="Portrait of Oré Akinsanmi, face shot with hand in prayer position in front of lips. Umber brown skin wit short tightly curled hair. Pensive dark brown eyes looking forward"
          layout="constrained"
        />
      </header>
      <main className="copy">


        <p className="copy-about">
          Trans. Black. Free. Oreolúwa is a
          nature loving Afro-indigenous creator based in the
          United States. They are passionate about empowering artists, small businesses and local community groups through user-centric web design. As a creative
          technologist they leverage JavaScript, HTML
          and CSS based frameworks or platforms (CMS included) to create exceptional user experiences.</p>
          <p>Let's <Link
            className="calendlyLink"
            to="https://calendly.com/ore-akins"
            target="_blank"
            rel="nofollow"><button className="primaryButton">
            create</button>
          </Link> an experience that represents your vision together!</p>
          <br />
      </main>
    </div>
  </Layout>

export default About;